/* eslint-disable no-param-reassign */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-shadow */
import axios from 'axios';
import { authHeader } from '@/helpers';
import config from '../../config';

import {
  CUSTOMERDB_REQUEST,
  CUSTOMERDB_SUCCESS,
  CUSTOMERDB_ERROR,
} from '../mutation-types';


const state = {
  customersdbs: [],
  loadingCustomersdbs: false,
};

const getters = {
  customersdbs: state => state.customersdbs,
  loadingCustomersdbs: state => state.loadingCustomersdbs,
};

const actions = {
  fetchCustomersdbs(store) {
    return new Promise((resolve, reject) => {
      store.commit(CUSTOMERDB_REQUEST, { });
      axios.get(config.API.ADMIN.CUSTOMERDB, {
        headers: authHeader(store),
      }).then((response) => {
        // if (response.data) {
        store.commit(CUSTOMERDB_SUCCESS, { customersdbs: response.data });
        resolve();
        // } else {
        //  reject(data.error);
        // }
      }).catch(() => {
        store.commit(CUSTOMERDB_ERROR);
        reject();
        // reject('Error sending request to server!');
      });
    });
  },
  fetchCustomersdbsByInstance(store, { instance }) {
    return new Promise((resolve, reject) => {
      store.commit(CUSTOMERDB_REQUEST, { });
      axios.get(`${config.API.ADMIN.CUSTOMERDB}/byinstance/${instance}`, {
        headers: authHeader(store),
      }).then((response) => {
        // if (response.data) {
        store.commit(CUSTOMERDB_SUCCESS, { customersdbs: response.data });
        resolve();
        // } else {
        //  reject(data.error);
        // }
      }).catch(() => {
        store.commit(CUSTOMERDB_ERROR);
        reject();
        // reject('Error sending request to server!');
      });
    });
  },
};
const mutations = {
  [CUSTOMERDB_SUCCESS](store, data) {
    store.customersdbs = data.customersdbs;
    store.loadingCustomersdbs = false;
  },
  [CUSTOMERDB_REQUEST](store) {
    store.loadingCustomersdbs = true;
  },
  [CUSTOMERDB_ERROR](store) {
    store.loadingCustomersdbs = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
