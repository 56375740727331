import { authHeaderUser } from '@/helpers';
import Repository from './Repository';

const resource = '/admin/servers';
export default {
  get() {
    return Repository.get(`${resource}`, { headers: authHeaderUser() });
  },
  delete(serverId) {
    return Repository.delete(`${resource}/${serverId}`, { headers: authHeaderUser() });
  }
};
