/* eslint-disable no-underscore-dangle */
import { authHeaderUser } from '@/helpers';
import Repository from './Repository';

const resource = '/admin/schedules';
export default {
  get() {
    return Repository.get(`${resource}`, { headers: authHeaderUser() });
  },
  update(scheduleId, schedule) {
    return Repository.patch(`${resource}/${scheduleId}`, schedule, { headers: authHeaderUser() });
  },
  getByServer(server) {
    return Repository.get(`${resource}/byserver/${server}`, { headers: authHeaderUser() });
  },
  getByCustomer(customer) {
    return Repository.get(`${resource}/bycustomer/${customer}`, { headers: authHeaderUser() });
  },
  getByInstance(instance) {
    return Repository.get(`${resource}/byinstance/${instance}`, { headers: authHeaderUser() });
  },
  add(schedule) {
    return Repository.post(`${resource}`, schedule, { headers: authHeaderUser() });
  },
  patch(schedule) {
    return Repository.patch(`${resource}/${schedule._id}`, schedule, { headers: authHeaderUser() });
  },
  getTaskReport({ schedule, days }) {
    const d = days || 15;
    return Repository.get(`/backup/schedule/${schedule}/report?days=${d}`, { headers: authHeaderUser() });
  },
  delete(scheduleId) {
    return Repository.delete(`${resource}/${scheduleId}`, { headers: authHeaderUser() });
  }
};
