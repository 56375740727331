<template>
  <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Asignar Cliente</p>
      </header>
        <form @submit.prevent="validateBeforeSubmit">
      <section class="modal-card-body">
        <b-field label="Cliente">
          <b-select v-model="model.customer" placeholder="" :loading="loadingCustomers" required>
            <option
              v-for="customer in customers"
              :value="customer._id"
              :key="customer._id">
              {{ customer.name }}
          </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Cerrar</button>
        <button class="button is-primary" type="submit">Guardar</button>
      </footer>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { RopositoryFactory } from '@/repository/RepositoryFactory';
import EventBus from '@/event-bus';

const customersRepository = RopositoryFactory.get('customers');
const usersRepository = RopositoryFactory.get('users');

export default {
  props: ['userId'],
  data() {
    return {
      model: {
        customer: '',
      },
      customers: [],
      loadingCustomers: false,
    };
  },
  mounted() {
    this.fetchCustomers();
  },
  computed: {
  },
  methods: {
    validateBeforeSubmit() {
      const self = this;
      this.$validator.validateAll().then((result) => {
        if (result) {
          usersRepository.addCustomer({ userId: self.userId, customerId: this.$data.model.customer })
            .then((saved) => {
              this.$toast.open({
                message: 'Cliente asignado',
                type: 'is-success',
                position: 'is-bottom',
              });
              console.log('saved', saved);
              EventBus.$emit('user-updated', 0);
              this.$parent.close();
            })
            .catch((err) => {
              this.$toast.open({
                message: `Error al guardar\r\n${err}`,
                type: 'is-danger',
                position: 'is-bottom',
              });
            });
        } else {
          this.$toast.open({
            message: 'Datos no válidos',
            type: 'is-danger',
            position: 'is-bottom',
          });
        }
      });
    },
    fetchCustomers() {
      this.loadingCustomers = true;
      // const { data } = await instancesRepository.getByServer(server);
      customersRepository.get()
        .then(res => this.customers = res.data)
        .finally(() => this.loadingCustomers = false);
    },
  },
  filters: {
  },
  components: {

  },

};
</script>
