import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import Buefy from 'buefy';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'vue-moment';
import VeeValidate from 'vee-validate';
import router from '@/router';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import store from './store';
import App from './App.vue';

import './registerServiceWorker';
import './main.scss';

import '@fortawesome/fontawesome-free/css/fontawesome.css';

library.add(fas);

VeeValidate.Validator.localize({
  es: {
    messages: {
      required: (field) => `El campo ${field} es requerido`,
      email: (field) => `El campo ${field} debe ser un email válido`,
      min: (field, { length }) => `El campo ${field} debe tener al menos ${length} caracteres`,
    },
  },
}
)

Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.use(moment);
Vue.use(VueAxios, axios);
Vue.use(Buefy, {
  // defaultIconComponent: FontAwesomeIcon,
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});
Vue.use(VeeValidate, {
  events: '',
  locale: 'es'
});

Vue.config.productionTip = false;

sync(store, router);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
