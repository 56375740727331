<template>
  <div class="modal-card" style="width: auto">

      <header class="modal-card-head">
        <p class="modal-card-title">Programación</p>
      </header>
        <form @submit.prevent="validateBeforeSubmit">
      <section class="modal-card-body">

        <b-field label="Habilitado">
          <b-switch v-model="model.enabled">{{ model.enabled | sino }}</b-switch>
        </b-field>
        <b-field label="Servidor">
          <b-select v-model="model.server" placeholder="" :loading="loadingServers" @input="serverSelected" required>
            <option
              v-for="server in servers"
              :value="server._id"
              :key="server._id">
              {{ server.name }}
          </option>
          </b-select>
        </b-field>
        <b-field label="Instancia">
          <b-select v-model="model.instance" placeholder="" :loading="loadingInstances" @input="instanceSelected" required>
            <option
              v-for="instance in instances"
              :value="instance._id"
              :key="instance._id">
              {{ instance.name }}
          </option>
          </b-select>
        </b-field>
        <b-field label="Base de datos">
          <b-select v-model="model.customerdbs" placeholder="" :loading="loadingCustomersdbs" required>
            <option
              v-for="db in customersdbs"
              :value="db._id"
              :key="db._id">
              {{ db.database }} ({{ db.customer.name }})
          </option>
          </b-select>
        </b-field>
        <b-field label="Dias">
          <weekdays v-model="model.days"></weekdays>
        </b-field>
        <b-field label="Horario">
          <b-timepicker v-model="timeCmp" inline :increment-minutes="5"></b-timepicker>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Cerrar</button>
        <button class="button is-primary" type="submit">Guardar</button>
      </footer>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EventBus from '@/event-bus';
import { RopositoryFactory } from '../../../repository/RepositoryFactory';
import Weekdays from '../../Weekdays.vue';

const serversRepository = RopositoryFactory.get('servers');
const instancesRepository = RopositoryFactory.get('instances');
const customersdbsRepository = RopositoryFactory.get('customersdbs');
const schedulesRepository = RopositoryFactory.get('schedules');
// import serversRepository from '../../../repository/serversRepository';
// import instancesRepository from '../../../repository/instancesRepository';
// import customersdbsRepository from '../../../repository/customersdbsRepository';
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const timeInit = new Date();
    timeInit.setHours(0);
    timeInit.setMinutes(0);
    return {
      model: {
        enabled: true,
        server: '',
        instance: '',
        customerdbs: {
          _id: '',
          database: '',
          customer: '',
          instance: '',
        },
        days: {
          mon: true,
          tue: true,
          wed: true,
          thu: true,
          fri: true,
          sat: true,
          sun: true,
        },
        time: {
          hour: 0,
          minute: 0,
        },
      },
      adderror: {},
      servers: [],
      instances: [],
      customersdbs: [],
      loading: false,
      loadingServers: false,
      loadingInstances: false,
      loadingCustomersdbs: false,
    };
  },
  async mounted() {
    // console.log(this.props);
    // this.fetchServers();
    //debugger
/*     if (this.edit) {
      this.loadingServers = true;
      this.loadingInstances = true;
      this.loadingCustomersdbs = true;
      serversRepository.get()
        .then((servers) => {
        // this.loadingServers = true;
          
          instancesRepository.getByServer(this.value.server)
            .then((instances) => {
            // this.loadingInstances = true;
              this.instances = instances.data;
              customersdbsRepository.getByInstance(this.value.instance)
                .then((dbs) => {
                  this.servers = servers.data;
                  this.customersdbs = dbs.data;
                  this.model = this.value;
                  this.loadingServers = false;
                  this.loadingInstances = false;
                  this.loadingCustomersdbs = false;
                });
            });
        });
    } else {
      this.fetchServers();
    } */
    if (!this.edit) {
      this.fetchServers();
      return
    } 
    this.loadingServers = true;
    this.loadingInstances = true;
    this.loadingCustomersdbs = true;
    const servers = await serversRepository.get();
    const instance = await instancesRepository.getByServer(this.value.server);
    const dbs = await customersdbsRepository.getByInstance(this.value.instance);
    this.servers = servers.data;
    this.instances = instance.data;
    this.customersdbs = dbs.data;
    this.model = this.value;
    this.loadingServers = false;
    this.loadingInstances = false;
    this.loadingCustomersdbs = false;
  },
  computed: {
    // ...mapGetters('servers', ['servers', 'loadingServers']),
    // ...mapGetters('instances', ['instances', 'loadingInstances']),
    // ...mapGetters('customersdbs', ['customersdbs', 'loadingCustomersdbs']),
    daySelected: {
      get() {
        return this.model.days.mon
        || this.model.days.tue
        || this.model.days.wed
        || this.model.days.thu
        || this.model.days.fri
        || this.model.days.sat
        || this.model.days.sun;
      },

    },
    timeCmp: {
      get() {
        const date = new Date();
        date.setHours(this.model.time.hour);
        date.setMinutes(this.model.time.minute);
        return date;
      },
      set(n) {
        this.model.time.hour = n.getHours();
        this.model.time.minute = n.getMinutes();
      },
    },
  },
  methods: {
    // ...mapActions('servers', ['fetchServers']),
    // ...mapActions('instances', ['fetchInstancesByServer']),
    // ...mapActions('customersdbs', ['fetchCustomersdbsByInstance']),
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.edit) {
            schedulesRepository.update(this.$data.model.id, this.$data.model)
              .then((saved) => {
                this.$toast.open({
                  message: 'Programación creada',
                  type: 'is-success',
                  position: 'is-bottom',
                });
                EventBus.$emit('schedule-created', saved._id);
                this.$parent.close();
              })
              .catch((err) => {
                this.$toast.open({
                  message: `Error al guardar\r\n${err}`,
                  type: 'is-danger',
                  position: 'is-bottom',
                });
              });
          } else {
            schedulesRepository.add(this.$data.model)
              .then((saved) => {
                this.$toast.open({
                  message: 'Programación creada',
                  type: 'is-success',
                  position: 'is-bottom',
                });
                EventBus.$emit('schedule-created', saved._id);
                this.$parent.close();
              })
              .catch((err) => {
                this.$toast.open({
                  message: `Error al guardar\r\n${err}`,
                  type: 'is-danger',
                  position: 'is-bottom',
                });
              });
          }
        } else {
          this.$toast.open({
            message: 'Datos no válidos',
            type: 'is-danger',
            position: 'is-bottom',
          });
        }
      });
    },
    async fetchServers() {
      this.loadingServers = true;
      const { data } = await serversRepository.get();
      this.loadingServers = false;
      this.servers = data;
    },
    async fetchInstancesByServer({ server }) {
      this.loadingInstances = true;
      // const { data } = await instancesRepository.getByServer(server);
      const instances = await instancesRepository.getByServer(server)
      this.instances = instances.data;
        //.then(res => this.instances = res.data)
        //.finally(() => this.loadingInstances = false);
      this.loadingInstances = false;
      // this.instances = data;
    },
    async fetchCustomersdbsByInstance({ instance }) {
      this.loadingCustomersdbs = true;
      const { data } = await customersdbsRepository.getByInstance(instance);
      this.loadingCustomersdbs = false;
      this.customersdbs = data;
    },
    async serverSelected(server) {
      this.model = {
        ...this.model,
        instance: '',
        customerdbs: {
          _id: '',
          database: '',
          customer: '',
          instance: '',
        },
      }
      this.instances = [];
      this.customersdbs = [];
      await this.fetchInstancesByServer({ server });
    },
    async instanceSelected(instance) {
      await this.fetchCustomersdbsByInstance({ instance });
      //this.customersdbs = [];
    },
  },
  // props:['value'],
  filters: {
    sino(value) {
      if (value) return 'Si';
      return 'No';
    },
  },
  components: {
    Weekdays,
  },

};
</script>
