export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS';
export const ME_SUCESS = 'ME_SUCESS';
export const ME_ERROR = 'ME_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const RESET_SUCCESS = 'RESET_SUCCESS';

export const CUSTOMERS_SUCCESS = 'CUSTOMERS_SUCCESS';

export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_REQUEST = 'STATUS_REQUEST';
export const STATUS_ERROR = 'STATUS_ERROR';

export const DOWNLOAD_START = 'DOWNLOAD_START';
export const DOWNLOAD_END = 'DOWNLOAD_END';

export const SERVER_REQUEST = 'SERVER_REQUEST';
export const SERVER_SUCCESS = 'SERVER_SUCCESS';
export const SERVER_ERROR = 'SERVER_ERROR';

export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST';
export const CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';

export const SCHEDULE_REQUEST = 'SCHEDULE_REQUEST';
export const SCHEDULE_SUCCESS = 'SCHEDULE_SUCCESS';
export const SCHEDULE_ERROR = 'SCHEDULE_ERROR';

export const CUSTOMERDB_REQUEST = 'CUSTOMERDB_REQUEST';
export const CUSTOMERDB_SUCCESS = 'CUSTOMERDB_SUCCESS';
export const CUSTOMERDB_ERROR = 'CUSTOMERDB_ERROR';

export const INSTANCE_REQUEST = 'INSTANCE_REQUEST';
export const INSTANCE_SUCCESS = 'INSTANCE_SUCCESS';
export const INSTANCE_ERROR = 'INSTANCE_ERROR';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
