import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import users from './modules/users';
import backup from './modules/backup';
import servers from './modules/servers';
import customers from './modules/customers';
import schedules from './modules/schedules';
import customersdbs from './modules/customersdbs';
import instances from './modules/instances';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    user,
    users,
    backup,
    servers,
    customers,
    schedules,
    customersdbs,
    instances,
  },
});
