<template>
  <nav class="navbar is-light" style="margin-bottom: 10px;">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <b-icon icon="home"></b-icon>
      </router-link>

      <button class="button navbar-burger" aria-label="menu" aria-expanded="false" @click="showNav = !showNav" :class="{ 'is-active': showNav }">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
    <!--
    <div v-if="isLogged" class="navbar-menu" :class="{ 'is-active': showNav }">
      <div class="navbar-end">
        <div class="navbar-item">
          <router-link to="/profile">
              <b-icon icon="user"></b-icon>
              Perfil
            </router-link>
        </div>
        <b-dropdown position="is-bottom-left" aria-role="menu">
          <a class="navbar-item" slot="trigger">
            <span>Hola, <b>{{user.profile.name}}</b></span>
            <b-icon icon="angle-down"></b-icon>
          </a>
          <b-dropdown-item has-link>
            <router-link to="/profile">
              <b-icon icon="user"></b-icon>
              Perfil
            </router-link>
          </b-dropdown-item>
          <hr class="dropdown-divider">
          <b-dropdown-item has-link v-if="isRoot">
            <router-link to="/admin">
              <b-icon icon="server"></b-icon>
              Administración
            </router-link>
          </b-dropdown-item>
          <hr class="dropdown-divider" v-if="isRoot">
          <b-dropdown-item value="logout" @click="logout">
              <b-icon icon="sign-out"></b-icon>
              Salir
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    -->
    <div v-if="isLogged && showNav" class="navbar-menu" :class="{ 'is-active': isLogged && showNav }">
      <div class="navbar-start">
        <a class="navbar-item" @click="showNav = !showNav">
            <router-link to="/profile"  >
              <b-icon icon="user"></b-icon>
              Perfil
            </router-link>
        </a>
        <div class="navbar-item has-dropdown is-hoverable" v-if="isRoot">
          <a class="navbar-link">
            Administracion
          </a>
          <div class="navbar-dropdown">
            <a class="navbar-item" @click="showNav = !showNav">
              <router-link to="/admin"  >
              <b-icon icon="cog"></b-icon>
              General
            </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/users"  >
                <b-icon icon="users"></b-icon>
                Usuarios
              </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/customers"  >
                <b-icon icon="users"></b-icon>
                Clientes
              </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/servers"  >
                <b-icon icon="server"></b-icon>
                Servidores
              </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/instances"  >
                <b-icon icon="server"></b-icon>
                Instancias
              </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/customerdbs"  >
                <b-icon icon="database"></b-icon>
                Bases de datos
              </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/schedules"  >
                <b-icon icon="calendar"></b-icon>
                Programación
              </router-link>
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item" @click="logout">
              <b-icon icon="sign-out"></b-icon>
              Salir
            </a>
          </div>
        </div>
      </div>
      <!--
      <div class="navbar-end">
        <b-dropdown position="is-bottom-left" aria-role="menu">
          <a class="navbar-item" slot="trigger">
            <span>Hola, <b>{{user.profile.name}}</b></span>
            <b-icon icon="angle-down"></b-icon>
          </a>
          <b-dropdown-item has-link>
            <router-link to="/profile">
              <b-icon icon="user"></b-icon>
              Perfil
            </router-link>
          </b-dropdown-item>
          <hr class="dropdown-divider">
          <b-dropdown-item has-link v-if="isRoot">
            <router-link to="/admin">
              <b-icon icon="server"></b-icon>
              Administración
            </router-link>
          </b-dropdown-item>
          <hr class="dropdown-divider" v-if="isRoot">
          <b-dropdown-item value="logout" @click="logout">
              <b-icon icon="sign-out"></b-icon>
              Salir
          </b-dropdown-item>
        </b-dropdown>
      </div>
      -->
    </div>

    <div v-if="isLogged && !showNav" class="navbar-menu">
      <div class="navbar-start" v-if="isRoot">
        <div class="navbar-item has-dropdown is-hoverable" >
          <a class="navbar-link">
            Administración
          </a>
          <div class="navbar-dropdown">
            <a class="navbar-item" has-link>
              <router-link to="/admin"  >
                <b-icon icon="cog"></b-icon>
                General
              </router-link>
            </a>
            <a class="navbar-item" has-link>
              <router-link to="/admin/users"  >
                <b-icon icon="users"></b-icon>
                Usuarios
              </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/customers"  >
                <b-icon icon="users"></b-icon>
                Clientes
              </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/servers"  >
                <b-icon icon="server"></b-icon>
                Servidores
              </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/instances"  >
                <b-icon icon="server"></b-icon>
                Instancias
              </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/customerdbs"  >
                <b-icon icon="database"></b-icon>
                Bases de datos
              </router-link>
            </a>
            <a class="navbar-item">
              <router-link to="/admin/schedules"  >
                <b-icon icon="calendar"></b-icon>
                Programación
              </router-link>
            </a>
          </div>
        </div>
      </div>
      <div class="navbar-end">
        <b-dropdown position="is-bottom-left" aria-role="menu">
          <a class="navbar-item" slot="trigger">
            <span>Hola, <b>{{user.profile?.name}}</b></span>
            <b-icon icon="angle-down"></b-icon>
          </a>
          <b-dropdown-item has-link>
            <router-link to="/profile">
              <b-icon icon="user"></b-icon>
              Perfil
            </router-link>
          </b-dropdown-item>
          <hr class="dropdown-divider">
          <b-dropdown-item value="logout" @click="logout">
              <b-icon icon="sign-out-alt"></b-icon>
              Salir
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <!--
    <div v-else class="navbar-menu">
      <div class="navbar-end">
        <router-link to="login" class="is-tab navbar-item">Login</router-link>
      </div>
    </div>
    -->
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showNav: false,
      adminMenu: [{
        path: '',
        label: '',
        icon: '',
      }],
    };
  },
  computed: mapGetters('user', ['isLogged', 'isRoot', 'user']),
  methods: {
    clickLink(index) {
      console.log('clic', index);
      this.showNav = !this.showNav;
    },
    logout() {
      this.$store.dispatch('user/logout');
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>
