<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          Backups
        </h1>
        <h2 class="subtitle">
          Estado de los backups en los últimos 15 días
        </h2>
      </div>
      <div class="buttons">
          <b-button type="is-primary" class="button" size="is-small" @click="getStatus" icon-left="sync-alt">
            <span>Actualizar</span>
          </b-button>
      </div>
      <b-tabs type="is-toggle">
        <b-tab-item v-for="stat in status"
            :key="stat.customer.id">
            <template slot="header">
              <span>{{ stat.customer.name }} <b-tag>{{ stat.databases.length }} </b-tag></span>
            </template>
            <b-tabs type="is-toggle">
              <b-tab-item v-for="(database, index) in stat.databases" :key="index">
                <template slot="header">
                  <span>{{ database.database }}</span>
                </template>
                <b-table :data="database.lista" :mobile-cards="true" detailed :show-detail-icon="true">
                  <template slot-scope="props">
                    <b-table-column field="date" label="Fecha">
                      {{ props.row.date | moment("DD-MM-YYYY") }}
                    </b-table-column>
                    <b-table-column field="date" label="Estado" v-if="api_version > 1">
                      {{ props.row | estado }}
                    </b-table-column>
                    <b-table-column field="date" label="Estado" v-else>
                      {{ props.row | estadov1 }}
                    </b-table-column>
                    <template v-if="api_version > 1">
                      <b-table-column centered v-if="props.row.uploadersql && props.row.uploadersql.result && props.row.uploadersql.result.id" label="Descarga">
                        <b-tooltip label="Descargar" type="is-light">
                          <button class="button is-white" @click="startDownload(props.row)">
                            <b-icon icon="cloud-download-alt"  v-if="!downloading" ></b-icon>
                            <b-icon pack="fa" icon="sync-alt" custom-class="fa-spin" v-if="downloading && taskDownloading==props.row.id" ></b-icon>
                          </button>
                        </b-tooltip>
                      </b-table-column>
                      <b-table-column centered v-else label="Descarga">
                        <b-tooltip label="No disponible" type="is-light">
                          <button class="button is-white">
                            <b-icon icon="cloud-download-alt"  type="is-light"></b-icon>
                          </button>
                        </b-tooltip>
                      </b-table-column>
                    </template>
                  </template>
                  <template slot="detail" slot-scope="props">
                    <template>
                      <section>
                        <b-field grouped group-multiline>
                          <div class="control">
                            <b-taglist attached>
                              <b-tag type="is-dark">Backup</b-tag>
                              <b-tag  v-if="props.row.backupsql.date_start" type="is-info">{{ props.row.backupsql.date_start | moment("DD-MM-YYYY HH:mm:ss") }}</b-tag>
                              <b-tag v-if="props.row.backupsql.date_end" type="is-success">{{ props.row.backupsql.date_end | moment("DD-MM-YYYY HH:mm:ss") }}</b-tag>
                            </b-taglist>
                          </div>
                          <div class="control">
                            <b-taglist attached>
                              <b-tag type="is-dark">Compresion</b-tag>
                              <b-tag v-if="props.row.compresorsql.date_start" type="is-info">{{ props.row.compresorsql.date_start | moment("DD-MM-YYYY HH:mm:ss") }}</b-tag>
                              <b-tag v-if="props.row.compresorsql.date_end" type="is-success">{{ props.row.compresorsql.date_end | moment("DD-MM-YYYY HH:mm:ss") }}</b-tag>
                            </b-taglist>
                          </div>
                          <div class="control">
                            <b-taglist attached>
                              <b-tag type="is-dark">Upload</b-tag>
                              <b-tag v-if="props.row.uploadersql.date_start" type="is-info">{{ props.row.uploadersql.date_start | moment("DD-MM-YYYY HH:mm:ss") }}</b-tag>
                              <b-tag v-if="props.row.uploadersql.date_end" type="is-success">{{ props.row.uploadersql.date_end | moment("DD-MM-YYYY HH:mm:ss") }}</b-tag>
                            </b-taglist>
                          </div>
                        </b-field>
                      </section>
                    </template>
                  </template>
                </b-table>
              </b-tab-item>
            </b-tabs>
        </b-tab-item>
      </b-tabs>
    </div>
    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false">
      <b-icon
          pack="fa"
          icon="sync-alt"
          size="is-large"
          custom-class="fa-spin">
      </b-icon>
      <h1>Cargando...</h1>
    </b-loading>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      downloading: false,
      taskDownloading: '',
      /*
      columns: [{
        field: 'date',
        label: 'Fecha',
      }, {
        field: 'finished',
        label: 'Finalizado',
      }, {
        label: 'Descarga',
        field: ''
      }],
      */
    };
  },
  filters: {
    nullableDate: (value) => {
      if (!value) return '';
      return moment(value).format('DD-MM-YYYY HH:mm:ss');
    },
    sino: (value) => {
      if (!value) return 'No';
      return 'Si';
    },
    estado: (row) => {
      try {
        if (!row) return 'No realizado';
        // f (!row.date_start) return 'No Realizado';

        // console.log(row.compresorsql);
        if (row.finished) return 'Finalizado';
        if (row.uploadersql.finished) return 'Finalizado';

        if (!row.uploadersql.status.progress.total_steps) return 'Pendiente de subir';
        if (row.uploadersql.date_start) return `Subiendo ${((row.uploadersql.status.progress.step / row.uploadersql.status.progress.total_steps) * 100).toFixed(2)} %`;

        if (row.compresorsql.finished) return 'Pendiente de subir';
        if (row.compresorsql.date_start) return 'Comprimiendo';


        if (row.backupsql.finished) return 'Pendiente de comprimir';
        if (row.backupsql.date_start) return `Realizando backup ${((row.backupsql.status.progress.step / row.backupsql.status.progress.total_steps) * 100).toFixed(2)} %`;
        return 'Pendiente';
      } catch {
        return 'No realizado';
      }
    },
    estadov1: (row) => {
      try {
        if (!row) return 'No realizado';
        // f (!row.date_start) return 'No Realizado';

        // console.log(row.compresorsql);
        if (row.finished) return 'Finalizado';
        if (row.uploadersql.finished) return 'Finalizado';

        if (!row.uploadersql.status.progress.total_steps) return 'Pendiente de subir';
        if (row.uploadersql.date_start) return `Subiendo ${((row.uploadersql.status.progress.step / row.uploadersql.status.progress.total_steps) * 100).toFixed(2)} %`;

        if (row.compresorsql.finished) return 'Pendiente de subir';
        if (row.compresorsql.date_start) return 'Comprimiendo';


        if (row.backupsql.finished) return 'Pendiente de comprimir';
        if (row.backupsql.date_start) return `Realizando backup ${((row.backupsql.status.progress.step / row.backupsql.status.progress.total_steps) * 100).toFixed(2)} %`;
        return 'Pendiente';
      } catch {
        return 'No realizado';
      }
    },
    pretty: value => JSON.stringify(JSON.parse(value), null, 2),
  },
  computed: {
    ...mapGetters('backup', ['status', 'loading']),
    ...mapGetters('user', ['api_version']),
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    ...mapActions('backup', ['getStatus', 'downloadBackup']),
    startDownload(row) {
      if (this.downloading) return;

      this.$dialog.confirm({
          message: '¿Iniciar el proceso de descarga?',
          onConfirm: () => {
            this.downloading = true;
            this.taskDownloading = row.id;
            this.downloadBackup(row)
              .then(() => {
                this.downloading = false;
                this.taskDownloading = '';
                this.$dialog.alert({
                  title: 'Descarga',
                  message: `Le enviamos por email los pasos para realizar la descarga del backup para el dia ${moment(row.date).format('DD-MM-YYYY')}`,
                  type: 'is-info',
                  hasIcon: true,
                  icon: 'info-circle',
                  iconPack: 'fa',
                });
              })
              .catch(() => {
                this.downloading = false;
                this.taskDownloading = '';
                this.$dialog.alert({
                  title: 'Descarga',
                  message: 'Se produjo un error al enviar el email. Intente nuevamente',
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'time-circle',
                  iconPack: 'fa',
                });
              });
          }
        });
    },
  },
};
</script>
