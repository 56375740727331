/* eslint-disable no-param-reassign */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-shadow */
import axios from 'axios';
import { authHeader } from '@/helpers';
import config from '../../config';

import {
  SCHEDULE_REQUEST,
  SCHEDULE_SUCCESS,
  SCHEDULE_ERROR,
} from '../mutation-types';


const state = {
  schedules: [],
  loadingSchedules: false,
};

const getters = {
  schedules: state => state.schedules,
  loadingSchedules: state => state.loadingSchedules,
};

const actions = {
  fetchSchedules(store) {
    return new Promise((resolve, reject) => {
      store.commit(SCHEDULE_REQUEST, { });
      axios.get(config.API.ADMIN.SCHEDULE, {
        headers: authHeader(store),
      }).then((response) => {
        // if (response.data) {
        store.commit(SCHEDULE_SUCCESS, { schedules: response.data });
        resolve();
        // } else {
        //  reject(data.error);
        // }
      }).catch(() => {
        store.commit(SCHEDULE_ERROR);
        reject();
        // reject('Error sending request to server!');
      });
    });
  },
};
const mutations = {
  [SCHEDULE_SUCCESS](store, data) {
    store.schedules = data.schedules;
    store.loadingSchedules = false;
  },
  [SCHEDULE_REQUEST](store) {
    store.loadingSchedules = true;
  },
  [SCHEDULE_ERROR](store) {
    store.loadingSchedules = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
