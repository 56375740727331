<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">Instancias</h1>
      </div>
    </div>
      <b-table :data="instances" :mobile-cards="true" :columns="columns">
        <template slot-scope="props">
          <b-table-column label="Id" field="_id" sortable>
            {{ props.row._id }}
          </b-table-column>
          <b-table-column label="Nombre" field="name" sortable>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Acciones">
              <div class="buttons is-primary" >
                <div v-if="!props.row.flags?.delete">
                  <b-button v-if="!props.row.flags?.delete" class="button is-danger" size="is-small" @click="deleteClick(props.row)">
                    <b-icon icon="times"></b-icon>
                  </b-button>
                </div>
                <b-tag v-else type="is-danger">Eliminando</b-tag>
              </div>
            </b-table-column>
        </template>
      </b-table>
      <b-loading :is-full-page="false" :active.sync="loadingInstances" :can-cancel="false">
        <b-icon
            pack="fa"
            icon="sync-alt"
            size="is-large"
            custom-class="fa-spin">
        </b-icon>
        <h1>Cargando...</h1>
      </b-loading>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { RopositoryFactory } from '@/repository/RepositoryFactory';
const instanceRepository = RopositoryFactory.get('instances');

const self = this;
export default {
  data() {
    return {
      columns: [
        {
          field: '_id',
          label: 'ID',
          sortable: true,
        }, {
          field: 'name',
          label: 'Nombre',
          sortable: true,
        },
      ],
    };
  },
  filters: {
  },
  computed: {
    ...mapGetters('instances', ['instances', 'loadingInstances']),
  },
  methods: {
    ...mapActions('instances', ['fetchInstances']),
    deleteClick(row) {
      this.$dialog.confirm({
        title: 'Eliminar',
        message: `Se va a eliminar la instancia y toda la informacion asociada (schedules, tareas y registros de ejecucion). 
        Esta acción no elimina los backups de dropbox, pero ya no se tendrá acceso a la descarga de los mismos desde el panel de backups`,
        type: 'is-danger',
        position: 'is-bottom',
        confirmText: 'Aceptar',
        focusOn: 'cancel',
        onConfirm: () => {
          instanceRepository.delete(row._id)
            .then(() => {
              this.$toast.open({
                message: 'Procesando eliminacion',
                type: 'is-success',
                position: 'is-bottom',
              });
              this.fetchInstances();
            });
        },
      });
    },
  },
  mounted() {
    this.fetchInstances();
  },
};
</script>
