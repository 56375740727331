<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">Servidores</h1>
      </div>
    </div>
      <b-table :data="servers" :mobile-cards="true" :columns="columns" detailed>
        <template slot-scope="props">
          <b-table-column label="Id" field="_id" sortable>
            {{ props.row._id }}
          </b-table-column>
          <b-table-column label="Nombre" field="name" sortable>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Estado" field="rmq.state" sortable>
            <template v-if="props.row.rmq">
              {{ props.row.rmq.state }}
              </template>
          </b-table-column>
          <b-table-column label="Acciones">
              <div class="buttons is-primary" >                
                <b-button v-if="!props.row.flags?.delete" class="button is-danger" size="is-small" @click="deleteClick(props.row)">
                  <b-icon icon="times"></b-icon>
                </b-button>               
                <b-tag v-else type="is-danger">Eliminando</b-tag>
              </div>
            </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <section>
            <b-field label="Conectado desde" v-if="props.row.rmq">
              {{ props.row.rmq.connected_at | moment("DD-MM-YYYY HH:mm:ss") }}
            </b-field>
            <b-field label="Ip" v-if="props.row.rmq">
              <b-input :value="props.row.rmq.peer_host" disabled></b-input>
            </b-field>
            <b-field label="Serial">
              <b-input :value="props.row.serial" disabled></b-input>
            </b-field>
          </section>
        </template>
      </b-table>
      <b-loading :is-full-page="false" :active.sync="loadingServers" :can-cancel="false">
        <b-icon
            pack="fa"
            icon="sync-alt"
            size="is-large"
            custom-class="fa-spin">
        </b-icon>
        <h1>Cargando...</h1>
      </b-loading>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { RopositoryFactory } from '@/repository/RepositoryFactory';

const serversRepository = RopositoryFactory.get('servers');

const self = this;
export default {
  data() {
    return {
      columns: [
        {
          field: '_id',
          label: 'ID',
          sortable: true,
        }, {
          field: 'name',
          label: 'Nombre',
          sortable: true,
        },
      ],
    };
  },
  filters: {
    since: (value) => {

    },
  },
  computed: {
    ...mapGetters('servers', ['servers', 'loadingServers']),
  },
  methods: {
    ...mapActions('servers', ['fetchServers']),
    deleteClick(row) {
      this.$dialog.confirm({
        title: 'Eliminar',
        message: `Se va a eliminar el servidor y toda la informacion asociada (instancias, schedules, tareas y registros de ejecucion). 
        Esta acción no elimina los backups de dropbox, pero ya no se tendrá acceso a la descarga de los mismos desde el panel de backups`,
        type: 'is-danger',
        position: 'is-bottom',
        confirmText: 'Aceptar',
        focusOn: 'cancel',
        onConfirm: () => {
          serversRepository.delete(row._id)
            .then(() => {
              this.$toast.open({
                message: 'Procesando eliminacion',
                type: 'is-success',
                position: 'is-bottom',
              });
              this.fetchServers();
            });
        },
      });
    },
  },
  mounted() {
    // this.fetchServers();
    this.fetchServers();
  },
};
</script>
