import { authHeaderUser } from '@/helpers';
import Repository from './Repository';

const resource = '/admin/instances';
export default {
  get() {
    return Repository.get(`${resource}`, { headers: authHeaderUser() });
  },
  getByServer(server) {
    return Repository.get(`${resource}/byserver/${server}`, { headers: authHeaderUser() });
  },
  delete(instanceId) {
    return Repository.delete(`${resource}/${instanceId}`, { headers: authHeaderUser() });
  }
};
