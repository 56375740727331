import ServersRepository from './serversRepository';
import InstancesRepository from './instancesRepository';
import CustomersdbRepository from './customersdbsRepository';
import SchedulesRepository from './schedulesRepository';
import CustomersRepository from './customersRepository';
import UsersRepository from './usersRepository';

const repositories = {
  servers: ServersRepository,
  instances: InstancesRepository,
  customersdbs: CustomersdbRepository,
  schedules: SchedulesRepository,
  customers: CustomersRepository,
  users: UsersRepository,
};

// eslint-disable-next-line import/prefer-default-export
export const RopositoryFactory = {
  get: name => repositories[name],
};
