import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import Login from '@/components/pages/Login.vue';
import Forgot from '@/components/pages/Forgot.vue';
import Reset from '@/components/pages/Reset.vue';
import UserHome from '@/components/pages/UserHome.vue';
import Admin from '@/components/pages//admin/Home.vue';
import Profile from '@/components/pages/Profile.vue';
import AdminServer from '@/components/pages/admin/Server.vue';
import AdminUsers from '@/components/pages/admin/Users.vue';
import AdminCustomers from '@/components/pages/admin/Customers.vue';
import AdminServers from '@/components/pages/admin/Servers.vue';
import AdminInstances from '@/components/pages/admin/Instances.vue';
import AdminCustomersdbs from '@/components/pages/admin/Customersdbs.vue';
import AdminSchedules from '@/components/pages/admin/Schedules.vue';

Vue.use(Router);

const router = new Router({
  linkActiveClass: 'is-active',
  routes: [
    {
      path: '/',
      name: 'home',
      component: UserHome,
      meta: { auth: true },
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { auth: true },
    },
    /*
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      meta: { auth: true },
    },
    */
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/forgot',
      name: 'Forgot',
      component: Forgot,
    },
    {
      path: '/reset/:forgot_token',
      name: '/reset',
      component: Reset,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: {
        auth: true,
        root: true,
      },
    }, {
      path: '/admin/server/:serverId',
      name: 'admin_server_id',
      component: AdminServer,
      props: true,
      meta: {
        auth: true,
        root: true,
      },
    }, {
      path: '/admin/users',
      name: 'admin_users',
      component: AdminUsers,
      meta: {
        auth: true,
        root: true,
      },
    }, {
      path: '/admin/customers',
      name: 'admin_customers',
      component: AdminCustomers,
      meta: {
        auth: true,
        root: true,
      },
    }, {
      path: '/admin/servers',
      name: 'admin_servers',
      component: AdminServers,
      meta: {
        auth: true,
        root: true,
      },
    }, {
      path: '/admin/instances',
      name: 'admin_instances',
      component: AdminInstances,
      meta: {
        auth: true,
        root: true,
      },
    }, {
      path: '/admin/customerdbs',
      name: 'admin_customerdbs',
      component: AdminCustomersdbs,
      meta: {
        auth: true,
        root: true,
      },
    }, {
      path: '/admin/schedules',
      name: 'admin_schedules',
      component: AdminSchedules,
      meta: {
        auth: true,
        root: true,
      },
    },
    /*
    {
      path: '/register',
      name: 'Register',
      component: Register,
    },
    */
  ],
});
router.beforeEach((to, from, next) => {
  const needAuth = to.matched.some(record => record.meta.auth);
  const needRoot = to.matched.some(record => record.meta.root);
  const isLogged = store.getters['user/isLogged'];
  const isRoot = store.getters['user/isRoot'];
  if (needAuth) {
    if (!isLogged) {
      // console.log('redirect login');
      return next({
        path: '/login',
      });
    }

    if (needRoot && !isRoot) {
      // console.log('redirect home');
      return next({
        path: '/',
      });
    }
    return next();
  }
  return next();
});

export default router;
