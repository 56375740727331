<template>
  <div class="columns">
    <div class="column is-4 is-offset-4">
      <card title="Establecer nueva contraseña" icon="key">
        <form @submit="makeReset">
          <b-field label="Contraseña">
            <b-input
              type="password"
              v-model="password"
              class="is-half"
              icon="key"
              minlength="8"
              required
              maxlength="20"
              :has-counter="false"
              password-reveal
            ></b-input>
          </b-field>
              <b-field label="Confirmar Contraseña">
                <b-input
                  type="password"
                  v-model="confirm"
                  icon="key"
                  minlength="8"
                  required
                  maxlength="20"
                  :has-counter="false"
                  password-reveal
                ></b-input>
              </b-field>
          <div class="has-text-danger has-text-centered">{{error}}</div>
          <button
            type="submit"
            :class="['button', 'is-primary', 'is-fullwidth',
            {'is-loading': pending}]">Enviar</button>
        </form>
      </card>
    </div>
  </div>

</template>

<script>
import Card from '../layout/Card.vue';

export default {
  data() {
    return {
      password: '',
      confirm: '',
      pending: false,
      error: '',
      token: this.$route.params.forgot_token,
    };
  },
  methods: {
    makeReset(e) {
      e.preventDefault();
      this.pending = true;
      this.$store.dispatch('user/reset', this)
        .then(() => {
          this.pending = false;
          this.$router.push({ name: 'home' });
        })
        .catch((err) => {
          this.error = err;
          this.pending = false;
        });
    },
  },
  components: {
    card: Card,
  },
};
</script>
