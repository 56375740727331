/* eslint-disable no-param-reassign */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-shadow */
import axios from 'axios';
import { authHeader } from '@/helpers';
import config from '../../config';
import {
  STATUS_SUCCESS,
  STATUS_REQUEST,
  STATUS_ERROR,
  DOWNLOAD_START,
  DOWNLOAD_END,
} from '../mutation-types';

const state = {
  status: [],
  loading: false,
  downloading: false,
};

const getters = {
  status: state => state.status,
  loading: state => state.loading,
};

const actions = {
  downloadBackup(store, { taskid }) {
    return new Promise((resolve, reject) => {
      store.commit(DOWNLOAD_START, {});
      axios.post(`${config.API.DOWNLOAD}/${taskid}/download`, {}, {
        headers: authHeader(store),
      }).then((response) => {
        store.commit(DOWNLOAD_END, { status: response.data });
        resolve();
      }).catch(() => {
        reject();
      });
    });
  },
  getStatus(store) {
    return new Promise((resolve, reject) => {
      store.commit(STATUS_REQUEST, { });
      axios.get(config.API.STATUS, {
        headers: authHeader(store),
      }).then((response) => {
        // if (response.data) {
        store.commit(STATUS_SUCCESS, { status: response.data });
        resolve();
        // } else {
        //  reject(data.error);
        // }
      }).catch(() => {
        store.commit(STATUS_ERROR);
        reject();
        // reject('Error sending request to server!');
      });
    });
  },
};
const mutations = {
  [STATUS_SUCCESS](store, data) {
    store.status = data.status;
    store.loading = false;
  },
  [STATUS_REQUEST](store) {
    store.loading = true;
  },
  [STATUS_ERROR](store) {
    store.loading = false;
  },
  [DOWNLOAD_START](store) {
    store.downloading = true;
  },
  [DOWNLOAD_END](store) {
    store.downloading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
