import { authHeaderUser } from '@/helpers';
import Repository from './Repository';

const resource = '/admin/customerdbs';
export default {
  get() {
    return Repository.get(`${resource}`, { headers: authHeaderUser() });
  },
  getByInstance(instance) {
    return Repository.get(`${resource}/byinstance/${instance}`, { headers: authHeaderUser() });
  },
  delete(databaseId) {
    return Repository.delete(`${resource}/${databaseId}`, { headers: authHeaderUser() });
  },
};
