<template>
    <b-table :data="customersdbs" :mobile-cards="true" :columns="columns">
    </b-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['customersdbs'],
  data() {
    return {
      columns: [
        {
          field: '_id',
          label: 'ID',
        }, {
          field: 'database',
          label: 'Base de datos',
        },
      ],
    };
  },
  computed: {

  },
  methods: {

  },
  mounted() {
    // this.fetchServers();
  },
};
</script>
