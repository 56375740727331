<template>
  <b-table :data="data" :mobile-cards="true">
  <template slot="empty">
    <p>No hay clientes asignados</p>
  </template>
  <template slot-scope="props">
    <b-table-column label="Nombre">
      {{ props.row.name}}
    </b-table-column>
    <b-table-column label="Acciones">
       <div class="buttons is-primary" >
        <b-button class="button is-danger" size="is-small" @click="removeCustomer(props.row._id)">
          <b-icon icon="times"></b-icon>
        </b-button>
      </div>
    </b-table-column>
  </template>
</b-table>
</template>
<script>
import { RopositoryFactory } from '@/repository/RepositoryFactory';
import EventBus from '@/event-bus';

const usersRepository = RopositoryFactory.get('users');
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: String,
      default: '',
    },
  },
  methods: {
    removeCustomer(customerId) {
      // console.log('removeCustomer', this.userId, customerId);

      this.$dialog.confirm({
        message: '¿Quitar cliente?',
        focusOn: 'cancel',
        onConfirm: () => {
          usersRepository.deleteCustomer({ userId: this.userId, customerId })
            .then(() => {
              this.$toast.open({
                message: 'Cliente desasignado',
                type: 'is-success',
                position: 'is-bottom',
              });
              EventBus.$emit('user-updated', 0);
            });
        },
      });
    },
  },
};
</script>
