/* eslint-disable no-param-reassign */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-shadow */
import axios from 'axios';
import { authHeader } from '@/helpers';
import config from '../../config';

import {
  CUSTOMER_REQUEST,
  CUSTOMER_SUCCESS,
  CUSTOMER_ERROR,
} from '../mutation-types';


const state = {
  customers: [],
  loadingCustomers: false,
};

const getters = {
  customers: state => state.customers,
  loadingCustomers: state => state.loadingCustomers,
};

const actions = {
  fetchCustomers(store) {
    return new Promise((resolve, reject) => {
      store.commit(CUSTOMER_REQUEST, { });
      axios.get(config.API.CUSTOMER, {
        headers: authHeader(store),
      }).then((response) => {
        // if (response.data) {
        store.commit(CUSTOMER_SUCCESS, { customers: response.data });
        resolve();
        // } else {
        //  reject(data.error);
        // }
      }).catch(() => {
        store.commit(CUSTOMER_ERROR);
        reject();
        // reject('Error sending request to server!');
      });
    });
  },
};
const mutations = {
  [CUSTOMER_SUCCESS](store, data) {
    store.customers = data.customers;
    store.loadingCustomers = false;
  },
  [CUSTOMER_REQUEST](store) {
    store.loadingCustomers = true;
  },
  [CUSTOMER_ERROR](store) {
    store.loadingCustomers = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
