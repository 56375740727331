<template>
  <div class="buttons field has-addons">
    <b-button @click="dayClick(0)" :inverted="!value.mon" class="is-small" :type="value.mon ? 'is-success' : ''">Lu</b-button>
    <b-button @click="dayClick(1)" :inverted="!value.tue" class="is-small" :type="value.tue ? 'is-success' : ''">Ma</b-button>
    <b-button @click="dayClick(2)" :inverted="!value.wed" class="is-small" :type="value.wed ? 'is-success' : ''">Mi</b-button>
    <b-button @click="dayClick(3)" :inverted="!value.thu" class="is-small" :type="value.thu ? 'is-success' : ''">Ju</b-button>
    <b-button @click="dayClick(4)" :inverted="!value.fri" class="is-small" :type="value.fri ? 'is-success' : ''">Vi</b-button>
    <b-button @click="dayClick(5)" :inverted="!value.sat" class="is-small" :type="value.sat ? 'is-success' : ''">Sa</b-button>
    <b-button @click="dayClick(6)" :inverted="!value.sun" class="is-small" :type="value.sun ? 'is-success' : ''">Do</b-button>
  </div>
</template>

<script>
export default {
  /*
  name () {
    return this.label;
  },
  $_veeValidate: {
    value() {
      const value = this.value;
      if (this.allowNoDays) {
        return value;
      }

      return value[0];
    },
    name() {
      return this.name;
    }
  },
  */
  data() {
    return {
      model: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      },
    };
  },
  props: {
    label: String,
    value: {
      type: Object,
      default: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      },
      allowNoDays: {
        type: Boolean,
        default: false,
      },
    },
  },
  methods: {
    dayClick(index) {
      const newValues = {
        mon: this.value.mon,
        tue: this.value.tue,
        wed: this.value.wed,
        thu: this.value.thu,
        fri: this.value.fri,
        sat: this.value.sat,
        sun: this.value.sun,
      };
      if (index === 0) {
        newValues.mon = !newValues.mon;
      }
      if (index === 1) {
        newValues.tue = !newValues.tue;
      }
      if (index === 2) {
        newValues.wed = !newValues.wed;
      }
      if (index === 3) {
        newValues.thu = !newValues.thu;
      }
      if (index === 4) {
        newValues.fri = !newValues.fri;
      }
      if (index === 5) {
        newValues.sat = !newValues.sat;
      }
      if (index === 6) {
        newValues.sun = !newValues.sun;
      }
      this.$emit('input', newValues);
    },
  },
};
</script>
