/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import axios from 'axios';
import config from '../../config';
import {
  CUSTOMERS_SUCCESS, USER_AUTH_SUCCESS, FORGOT_SUCCESS, USER_LOGOUT,
  ME_SUCESS,
} from '../mutation-types';
import { authHeader } from '../../helpers';

const state = {
  // token: sessionStorage.getItem('token') || '',
  token: localStorage.getItem('token') || '',
  // user: JSON.parse(sessionStorage.getItem('user')) || {},
  user: JSON.parse(localStorage.getItem('user')) || {},
};

const getters = {
  isLogged: state => !!state.token,
  user: state => state.user,
  api_version: state => state.user.api_version,
  token: state => state.token,
  isRoot: (state) => {
    if (Array.isArray(state.user.role)) {
      return state.user.role.role.indexOf('root') > -1;
    }
    return state.user.role === 'root';
  },
};

const actions = {
  me(store) {
    return new Promise((resolve, reject) => {
      axios.get(config.API.ME, {
        headers: authHeader(store),
      }).then(({ data }) => {
        if (data) {
          store.commit(ME_SUCESS, { data });
          resolve();
        } else {
          reject(data.error);
        }
      }).catch(() => {
        reject('Error sending request to server!');
      });
    });
  },
  reset(store, { token, password, confirm }) {
    return new Promise((resolve, reject) => {
      axios.post(config.API.RESET, {
        token,
        password,
        confirm,
      }).then(({ data }) => {
        if (data.success) {
          // store.commit(RESET_SUCCESS, { sucess: data.sucess });
          resolve();
        } else if (isArray(data.error)) {
          reject(data.error[0].msg);
        } else {
          reject(data.error);
        }
      }).catch(() => {
        reject('Error sending request to server!');
      });
    });
  },

  forgot(store, { email }) {
    return new Promise((resolve, reject) => {
      axios.post(config.API.FORGOT, {
        email,
      }).then(({ data }) => {
        if (data.success) {
          store.commit(FORGOT_SUCCESS, { sucess: data.sucess });
          resolve();
        } else {
          reject(data.error);
        }
      }).catch(() => {
        reject('Error sending request to server!');
      });
    });
  },
  async login3(store, { email, password }) {
    const response = await axios.post(config.API.LOGIN, { email, password });
    if (response.data) {
      const { token } = response.data;
      const usr = await axios.get(config.API.ME, { headers: authHeader(store) });
      if (usr.data) {
        store.commit(USER_AUTH_SUCCESS, { user: usr.data, token });
      }
    }
  },
  login(store, { email, password }) {
    return new Promise((resolve, reject) => {
      axios.post(config.API.LOGIN, {
        email,
        password,
      }).then(({ data }) => {
        if (data.success) {
          const { token } = data;
          const headers = { Authorization: `Bearer ${token}` };
          axios.get(config.API.ME, { headers })
            .then(({ data }) => {
              if (data) {
                delete data.customers;
                store.commit(USER_AUTH_SUCCESS, { user: data, token });
                resolve();
              } else {
                reject('Error sending request to server!');
              }
            });
        } else {
          reject(data.message);
        }
      }).catch(() => {
        reject('Usuario o contraseña no válido!');
      });
    });
  },
  login2(store, { email, password }) {
    return new Promise((resolve, reject) => {
      axios.post(config.API.LOGIN, {
        email,
        password,
      }).then(({ data }) => {
        if (data.success) {
          const { token } = data;
          store.commit(USER_AUTH_SUCCESS, { user: data, token });
          resolve();
        } else {
          reject(data.message);
        }
      }).catch(() => {
        reject('Error sending request to server!');
      });
    });
  },
  logout(store) {
    store.commit(USER_LOGOUT);
  },
};
const mutations = {
  [USER_AUTH_SUCCESS](store, { user, token }) {
    store.user = user;
    store.error = '';
    store.token = token;
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    // sessionStorage.setItem('token', token);
    // sessionStorage.setItem('user', JSON.stringify(user));
  },
  [USER_LOGOUT](store) {
    store.user = {};
    store.token = '';
    // sessionStorage.clear();
    localStorage.clear();
  },
  [FORGOT_SUCCESS](store) {
    store.forgot = true;
  },
  [CUSTOMERS_SUCCESS](store, data) {
    store.customers = data.customers;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
