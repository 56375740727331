<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Login</p>
    </header>
    <section class="modal-card-body">
      <b-field label="Habilitado">
        <b-switch v-model="value.enabled">{{ value.enabled | sino }}</b-switch>
      </b-field>
      <b-field label="Base de datos">
        <b-select v-model="value.customer_database.database" placeholder="Seleccione un item">
          <option value="siac">SIAC</option>
          <option value="sarec">SAREC</option>
          <option value="cac">CAC</option>
        </b-select>
      </b-field>
      <b-field label="Dias">
        <weekdays  @change="weekdayChange" :value="value.days"></weekdays>
      </b-field>
      <b-field label="Horario">
        <b-timepicker v-model="time" inline></b-timepicker>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Cerrar</button>
      <button class="button is-primary">Guardar</button>
    </footer>
  </div>
</template>

<script>
import Weekdays from '../../../Weekdays.vue';

export default {
  data() {
    return {
      model: this.value,
      enabled: false,
      database: 'siac',
      days,
    };
  },
  mounted() {
    console.log(this.props);
  },
  props: ['value'],
  filters: {
    sino(value) {
      if (value) return 'Si';
      return 'No';
    },
  },
  components: {
    Weekdays,
  },
  methods: {
    weekdayChange(newVal) {
      console.log(newVal);
    },
  },
};
</script>
