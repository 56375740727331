<template>
  <section class="hero">
    <div class="hero-body">
       <h1 class="title">Programaciones</h1>
    </div>
    <div>
      <div class="container">

      </div>
    </div>
      <div class="columns">
        <div class="column is-2">
          <b-button type="is-primary" outlined @click="newClick">Agregar</b-button>
        </div>
        <div class="column">
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
                  <b-field label="Dias">
          <b-numberinput v-model="days" :min="1" :max="15"></b-numberinput>
        </b-field>
        </div>
        <div class="column">
        </div>
      </div>
      <b-table :data="schedules" :mobile-cards="true" detailed detail-key="_id" :opened-detailed="openedDetailed" @details-open="scheduleDetailOpen" @details-close="scheduleDetailClose">
          <template slot-scope="props">
            <b-table-column label="Cliente" field="customer.name" sortable>
              <template v-if="props.row.customer">
                <p class="is-size-7-desktop">
              {{ props.row.customer.name }}

                </p>
              </template>
            </b-table-column>
            <b-table-column label="Servidor" field="server.name" sortable>
              <template v-if="props.row.server">
                <p class="is-size-7-desktop">
              {{ props.row.server.name }}
                </p>
              </template>
            </b-table-column>
            <b-table-column label="Instancia" field="instance.name" sortable>
              <template v-if="props.row.instance">
                <p class="is-size-7-desktop">
              {{ props.row.instance.name }}
                </p>
              </template>
            </b-table-column>
            <b-table-column label="Base de datos" field="customerdbs.database" sortable>
              <template v-if="props.row.customerdbs">
                <p class="is-size-7-desktop">
              {{ props.row.customerdbs.database }}
                </p>
              </template>
            </b-table-column>
            <b-table-column label="Habilitado">
              <b-switch v-model="props.row.enabled" size="is-small" disabled>
              </b-switch>
            </b-table-column>
            <b-table-column label="Dias">
              <weekdays :value="props.row.days"></weekdays>
            </b-table-column>
            <b-table-column label='Horario'>
              <p class="is-size-7-desktop">
              {{ props.row.time.hour | pad  }}:{{ props.row.time.minute | pad }}
              </p>
            </b-table-column>
            <b-table-column label="Acciones">
              <div class="buttons is-primary" >
                <div v-if="!props.row.flags?.delete">
                  <b-button class="button" size="is-small" @click="editClick(props.row)">
                  <b-icon icon="pencil-alt"></b-icon>
                </b-button>
                <b-button v-if="!props.row.flags?.delete" class="button is-danger" size="is-small" @click="deleteClick(props.row)">
                  <b-icon icon="times"></b-icon>
                </b-button>
                </div>
                <b-tag v-else type="is-danger">Eliminando</b-tag>
              </div>
            </b-table-column>
          </template>"
          <template slot="detail" slot-scope="props">
            <task-grid :data="props.row.tasks" :loading="loadingTasks" downloadable></task-grid>
          </template>
      </b-table>
      <b-loading :is-full-page="false" :active.sync="loadingSchedules" :can-cancel="false">
        <b-icon
            pack="fa"
            icon="sync-alt"
            size="is-large"
            custom-class="fa-spin">
        </b-icon>
        <h1>Cargando...</h1>
      </b-loading>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import EventBus from '@/event-bus';
import _ from 'lodash';
import { RopositoryFactory } from '@/repository/RepositoryFactory';
import ScheduleForm from './ScheduleForm.vue';
import Weekdays from '../../Weekdays.vue';
import TaskGrid from '../../TaskGrid.vue';

const schedulesRepository = RopositoryFactory.get('schedules');

const self = this;
export default {
  components: {
    TaskGrid,
    Weekdays,
  },
  data() {
    return {
      days: 15,
      schedules: [],
      loadingTasks: false,
      loadingSchedules: false,
      openedDetailed: [],
      columns: [
        {
          field: '_id',
          label: 'ID',
        }, {
          field: 'name',
          label: 'Nombre',
        },
      ],
    };
  },
  filters: {
    pad: (value) => {
      if (!value) return '00';
      // return moment(value).format('DD-MM-YYYY HH:mm:ss');
      return (`0${value}`).slice(-2);
    },
  },
  computed: {
    // ...mapGetters('schedules', ['schedules', 'loadingSchedules']),
  },
  methods: {
    // ...mapActions('schedules', ['fetchSchedules']),
    fetchSchedules() {
      this.loadingSchedules = true;
      schedulesRepository.get()
        .then((list) => {
          this.schedules = list.data;
        })
        .finally(() => {
          this.loadingSchedules = false;
        });
    },
    newClick() {
      this.$modal.open({
        parent: this,
        component: ScheduleForm,
        hasModalCard: true,
      });
    },
    editClick(row) {
      this.$modal.open({
        parent: this,
        component: ScheduleForm,
        hasModalCard: true,
        props: {
          edit: true,
          value: {
            id: row._id,
            enabled: row.enabled,
            server: row.server._id,
            instance: row.instance._id,
            customerdbs: row.customerdbs._id,
            days: row.days,
            time: row.time,
          },
        },
      });
    },
    scheduleCreated(id) {
      // console.log('schedule-created', id);
      this.fetchSchedules();
    },
    scheduleDetailOpen(row) {
      this.loadingTasks = true;
      schedulesRepository.getTaskReport({ schedule: row._id, days: this.days })
        .then((list) => {
          const index = _.findIndex(this.schedules, o => o._id === row._id);
          if (index >= 0) {
            this.schedules[index].tasks = list.data;
            this.openedDetailed = [row._id];
          }
        // row.tasks = list.data;
        // this.tasks[row._id] = list.data;
        })
        .finally(() => {
          this.loadingTasks = false;
        });
    },
    scheduleDetailClose(row) {
      const index = _.findIndex(this.schedules, o => o._id === row._id);
      if (index >= 0) {
        this.schedules[index].tasks = [];
      }
    },
    deleteClick(row) {
      this.$dialog.confirm({
        title: 'Eliminar',
        message: `Se va a eliminar la programacion, sus tareas asociadas y los registros de ejecucion. 
        Esta acción no elimina el backup de dropbox, pero ya no se tendrá acceso a la descarga del backup desde el panel de backups`,
        type: 'is-danger',
        position: 'is-bottom',
        confirmText: 'Aceptar',
        focusOn: 'cancel',
        onConfirm: () => {
          schedulesRepository.delete(row._id)
            .then(() => {
              this.$toast.open({
                message: 'Procesando eliminacion',
                type: 'is-success',
                position: 'is-bottom',
              });
              this.fetchSchedules();
            });
        },
      });
    },
  },
  mounted() {
    this.fetchSchedules();
    const self = this;
    EventBus.$on('schedule-created', (id) => {
      console.log('schedule-created', id);
      self.fetchSchedules();
    });
  },
  destroyed() {
    EventBus.$off('schedule-created');
  },
};
</script>
