<template>
  <div id="app">
    <navbar/>
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Navbar from './components/partials/Navbar.vue';

export default {
  name: 'app',
  components: {
    navbar: Navbar,
  },
  created() {
    const self = this;
    axios.interceptors.response.use(undefined, err => new Promise(((resolve, reject) => {
      if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
        // if you ever get an unauthorized, logout the user
        
        self.$store.dispatch('logout', null, { root: true });
        self.$router.push({ name: 'Login' });
        resolve();
        // you can also redirect to /login if needed !
      }
      throw err;
    })));
  },
};
</script>
