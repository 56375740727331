const root = 'http://api2.tera.com.ar:8002/api/';
//const root = 'http://localhost:8001/api/';
export default {
  root,
  API: {
    ADMIN: {
      USER: `${root}admin/users`,
      SERVER: `${root}admin/servers`,
      INSTANCE: `${root}admin/instances`,
      CUSTOMER: `${root}admin/customers`,
      CUSTOMERDB: `${root}admin/customerdbs`,
      SCHEDULE: `${root}admin/schedules`,
    },
    LOGIN: `${root}user/login`,
    ME: `${root}user/me`,
    REGISTER: `${root}user/register`,
    FORGOT: `${root}user/forgot`,
    RESET: `${root}user/reset`,
    BACKUP: `${root}status`,
    DATABASES: `${root}backup/databases`,
    CUSTOMERS: `${root}user/customers`,
    STATUS: `${root}backup/full`,
    DOWNLOAD: `${root}task`,
    SERVER: `${root}server`,
    CUSTOMER: `${root}customer`,
    SCHEDULE: `${root}schedule`,
  },
};
