<template>
  <div class="card">
    <header class="card-head">
      <p class="card-header-title">Servidor</p>
    </header>
    <section class="card-body">
      <b-field label="Nombre">

      </b-field>
      <instance-grid :data="instances">
      </instance-grid>
      <b-loading :is-full-page="false" :active.sync="loadingInstances" :can-cancel="false">
        <b-icon
            pack="fa"
            icon="sync-alt"
            size="is-large"
            custom-class="fa-spin">
        </b-icon>
        <h1>Cargando...</h1>
      </b-loading>
    </section>
  </div>
</template>

    <!--

    -->

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import InstanceGrid from '../../InstanceGrid.vue';

const self = this;
export default {
  props: {
    serverId: {
      type: String,
      default: '',
    },
  },
  components: {
    InstanceGrid,
  },
  data() {
    return {

    };
  },
  filters: {
  },
  computed: {
    ...mapGetters('server', ['servers', 'loadingServers']),
    ...mapGetters('instance', ['instances', 'loadingInstances']),
  },
  methods: {
    ...mapActions('server', ['fetchServers']),
    ...mapActions('instance', ['fetchInstances', 'fetchInstancesByServer']),
  },
  created() {
    // this.fetchServers();
    // this.$nextTick(function() {
    this.fetchInstancesByServer({ server: this.serverId });
    // });
  },
};
</script>
