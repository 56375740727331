<template>
  <div class="columns">
    <div class="column is-4 is-offset-4">
      <card title="Restablecer contraseña" icon="key">
        <p class="content is-small">Ingrese su email para restablecer su contraseña. Recibirá un correo con los pasos siguientes</p>
        <form @submit="makeForgot">
          <b-field label="Email">
            <b-input
              type="email"
              name="sandel"
              icon="envelope"
              required
              v-model="email"
            ></b-input>
          </b-field>
          <div class="has-text-danger has-text-centered">{{error}}</div>
          <button type="submit" :class="['button', 'is-primary', 'is-fullwidth', {'is-loading': pending}]">Enviar</button>
        </form>
      </card>
    </div>
  </div>

</template>

<script>
import Card from '../layout/Card.vue';

export default {
  data() {
    return {
      email: '',
      error: '',
      pending: false,
    };
  },
  methods: {
    makeForgot(e) {
      e.preventDefault();
      this.pending = true;
      this.$store.dispatch('user/forgot', this)
        .then(() => {
          this.pending = false;
          this.error = 'Email enviado!';
          // this.$router.push({ name: 'home' });
        })
        .catch((err) => {
          this.pending = false;
          this.error = err;
        });
    },
  },
  components: {
    card: Card,
  },
};
</script>
