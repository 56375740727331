<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">Bases de datos</h1>
      </div>
    </div>
    <b-field grouped group-multiline>
      <div class="control">
        <b-button type="is-primary" outlined>Agregar</b-button>
      </div>
      </b-field>
      <b-table :data="customersdbs" :mobile-cards="true" :columns="columns">
        <template slot-scope="props">
          <b-table-column label="Id" field="_id" sortable>
            {{ props.row._id }}
          </b-table-column>
          <b-table-column label="Servidor" field="server.name" sortable>
            {{ props.row.server?.name }}
          </b-table-column>
          <b-table-column label="Instancia" field="instance.name" sortable>
            {{ props.row.instance?.name }}
          </b-table-column>
          <b-table-column label="Cliente" field="customer.name" sortable>
            {{ props.row.customer?.name }}
          </b-table-column>
          <b-table-column label="Base de datos" field="database" sortable>
            {{ props.row.database }}
          </b-table-column>
          <b-table-column label="Acciones">
              <div class="buttons is-primary" >
                <div v-if="!props.row.flags?.delete">
                  <b-button v-if="!props.row.flags?.delete" class="button is-danger" size="is-small" @click="deleteClick(props.row)">
                    <b-icon icon="times"></b-icon>
                  </b-button>
              </div>
              <b-tag v-else type="is-danger">Eliminando</b-tag>

              </div>
            </b-table-column>
        </template>
      </b-table>
      <b-loading :is-full-page="false" :active.sync="loadingCustomersdbs" :can-cancel="false">
        <b-icon
            pack="fa"
            icon="sync-alt"
            size="is-large"
            custom-class="fa-spin">
        </b-icon>
        <h1>Cargando...</h1>
      </b-loading>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { RopositoryFactory } from '@/repository/RepositoryFactory';
const customersdbsRepository = RopositoryFactory.get('customersdbs');

const self = this;
export default {
  data() {
    return {
      columns: [
        {
          field: '_id',
          label: 'ID',
          sortable: true,
        }, {
          field: 'server.name',
          label: 'Servidor',
          sortable: true,
        }, {
          field: 'instance.name',
          label: 'Instancia',
          sortable: true,
        }, {
          field: 'customer.name',
          label: 'Cliente',
          sortable: true,
        }, {
          field: 'database',
          label: 'Base de datos',
          sortable: true,
        },
      ],
    };
  },
  filters: {
  },
  computed: {
    ...mapGetters('customersdbs', ['customersdbs', 'loadingCustomersdbs']),
  },
  methods: {
    ...mapActions('customersdbs', ['fetchCustomersdbs']),
    deleteClick(row) {
      this.$dialog.confirm({
        title: 'Eliminar',
        message: `Se va a eliminar la base de datos y toda la informacion asociada (schedules, tareas y registros de ejecucion). 
        Esta acción no elimina los backups de dropbox, pero ya no se tendrá acceso a la descarga de los mismos desde el panel de backups`,
        type: 'is-danger',
        position: 'is-bottom',
        confirmText: 'Aceptar',
        focusOn: 'cancel',
        onConfirm: () => {
          customersdbsRepository.delete(row._id)
            .then(() => {
              this.$toast.open({
                message: 'Procesando eliminacion',
                type: 'is-success',
                position: 'is-bottom',
              });
              this.fetchCustomersdbs();
            });
        },
      });
    },
  },
  mounted() {
    this.fetchCustomersdbs();
  },
};
</script>
