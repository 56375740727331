import axios from 'axios';
import store from '@/store';
import router from '@/router';
import config from '../config';

const bearerToken = store.getters['user/token'];
const ax = axios.create({
  baseURL: config.root,
  headers: {
    Authorization: `Bearer ${bearerToken}`,
  },
});

ax.interceptors.response.use(undefined, err => new Promise(((resolve, reject) => {
  if (err.response.status === 401) {
    console.log('repopsitory 401 error', err.response.data);
    store.dispatch('logout', null, { root: true });
    router.push({ name: 'Login' });
    resolve();
  }
})));

export default ax;
/*
export default axios.create({
  baseURL: config.root,
  headers: {
    Authorization: `Bearer ${bearerToken}`,
  },
});
*/
