<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">Usuarios</h1>
      </div>
    </div>
      <b-field grouped group-multiline>
        <div class="control">
          <b-button type="is-primary" outlined @click="newClick">Agregar</b-button>
        </div>
      </b-field>
      <b-table :data="users" :mobile-cards="true" :columns="columns" detailed>
        <template slot-scope="props">
          <b-table-column label="E-mail" sortable field="email">
            {{ props.row.email}}
          </b-table-column>
          <b-table-column label="Acciones">
            <div class="buttons">
              <b-button class="button is-danger" size="is-small" @click="deleteUser(props.row._id)">
                <b-icon icon="times"></b-icon>
              </b-button>
            </div>
          </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <div class="card">
            <div class="card-header">
              <p class="card-header-title">
                Clientes
              </p>
              <div class="card-header-icon">
                <b-button class="is-primary" icon-left="plus" outlined @click="addCustomer(props.row._id)">
                    Asignar cliente
                  </b-button>
              </div>
            </div>
            <div class="card-content">
              <user-customer-grid :data="props.row.customers" :userId="props.row._id">
              </user-customer-grid>
            </div>
          </div>

        </template>
      </b-table>
      <b-loading :is-full-page="false" :active.sync="loadingUsers" :can-cancel="false">
        <b-icon
            pack="fa"
            icon="sync-alt"
            size="is-large"
            custom-class="fa-spin">
        </b-icon>
        <h1>Cargando...</h1>
      </b-loading>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import UserCustomerGrid from '@/components/pages/admin/Users/UserCustomerGrid.vue';
import UserForm from '@/components/pages/admin/Users/UserForm.vue';
import { RopositoryFactory } from '@/repository/RepositoryFactory';
import EventBus from '@/event-bus';
import UserCustomerForm from './Users/UserCustomerForm.vue';

const usersRepository = RopositoryFactory.get('users');
const self = this;
export default {
  components: {
    UserCustomerGrid,
  },
  data() {
    return {
      columns: [
        {
          field: '_id',
          label: 'ID',
        }, {
          field: 'email',
          label: 'E-mail',
        },
      ],
    };
  },
  filters: {
  },
  computed: {
    ...mapGetters('users', ['users', 'loadingUsers']),
  },
  methods: {
    ...mapActions('users', ['fetchUsers']),
    newClick() {
      this.$modal.open({
        parent: this,
        component: UserForm,
        hasModalCard: true,
      });
    },
    addCustomer(userId) {
      this.$modal.open({
        parent: this,
        component: UserCustomerForm,
        hasModalCard: true,
        props: {
          userId,
        },
      });
    },
    deleteUser(userId) {
      this.$dialog.confirm({
        message: '¿Eliminar usuario?',
        focusOn: 'cancel',
        onConfirm: () => {
          usersRepository.delete({ userId })
            .then(() => {
              this.$toast.open({
                message: 'Usuario eliminado',
                type: 'is-success',
                position: 'is-bottom',
              });
              EventBus.$emit('user-updated', userId);
            });
        },
      });
    },
  },
  mounted() {
    this.fetchUsers();
    const self = this;
    EventBus.$on('user-updated', (id) => {
      console.log('user-updated', id);
      self.fetchUsers();
    });
  },
  destroyed() {
    EventBus.$off('user-updated');
  },
};
</script>
