<template>
  <b-table :data="data" :mobile-cards="true" :columns="columns" detailed detail-key="_id" @details-open="onDetail">
    <template slot="details" slot-scope="props">
      <customerdbs-grid :key="_id" :customersdbs="customersdbs"></customerdbs-grid>
    </template>
  </b-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CustomerdbsGrid from './CustomerdbsGrid.vue';

export default {
  components: {
    CustomerdbsGrid,
  },
  props: ['data'],
  data() {
    return {
      columns: [
        {
          field: '_id',
          label: 'ID',
        }, {
          field: 'name',
          label: 'Nombre',
        }, {
          field: 'sql_server',
          label: 'Conexion',
        }, {
          field: 'sql_auth',
          label: 'Autenticacion',
        }, {
          field: 'version',
          label: 'Version SQL',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('customersdbs', ['customersdbs']),
  },
  methods: {
    ...mapActions('customersdbs', ['fetchCustomerdbsByInstance']),
    onDetail(row, index) {
      this.fetchCustomerdbsByInstance({ instance: row._id });
    },
  },
  mounted() {
    // this.fetchServers();
  },
};
</script>
