<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">Administracion</h1>
      </div>
    </div>
    <b-tabs class="block" @change="tabChange">
      <b-tab-item label="Clientes">
        <b-table :data="customers" :mobile-cards="true" :columns="columns">
        </b-table>
        <b-loading :is-full-page="false" :active.sync="loadingCustomers" :can-cancel="false">
          <b-icon
              pack="fa"
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin">
          </b-icon>
          <h1>Cargando...</h1>
        </b-loading>
      </b-tab-item>
      <b-tab-item label="Servidores new">
        <server-grid :data="servers">
        </server-grid>
        <b-loading :is-full-page="false" :active.sync="loadingServers" :can-cancel="false">
          <b-icon
              pack="fa"
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin">
          </b-icon>
          <h1>Cargando...</h1>
        </b-loading>
      </b-tab-item>
      <b-tab-item label="Servidores">
        <b-table :data="servers" :mobile-cards="true" :columns="columns" detailed detail-key="_id" @details-open="onServerDetail">
           <template slot-scope="props">
             <b-table-column field="_id" label="Id">
               <router-link :to="{ name: 'admin_server_id', params: { serverId: props.row._id}}">{{ props.row._id }}</router-link>
             </b-table-column>
           </template>
           <template slot="detail" slot-scope="props">
             <b-table :data="instances" :columns="instanceColumns" :mobile-cards="true" detailed detail-key="_id" @details-open="onInstanceDetail">
               <template slot="details" slot-scope="props">
                 <b-table :data="customersdbs" :columns="customersdbsColumns" :mobile-cards="true">

                 </b-table>
               </template>
             </b-table>
           </template>
        </b-table>
        <b-loading :is-full-page="false" :active.sync="loadingServers" :can-cancel="false">
          <b-icon
              pack="fa"
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin">
          </b-icon>
          <h1>Cargando...</h1>
        </b-loading>
      </b-tab-item>
      <b-tab-item label="Instancias">
        <instance-grid :data="instances">
        </instance-grid>
        <b-loading :is-full-page="false" :active.sync="loadingInstances" :can-cancel="false">
          <b-icon
              pack="fa"
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin">
          </b-icon>
          <h1>Cargando...</h1>
        </b-loading>
      </b-tab-item>
      <b-tab-item label="Bases de datos">
        <customerdbs-grid :customersdbs="customersdbs"></customerdbs-grid>
      </b-tab-item>
      <b-tab-item label="Programaciones">
        <b-field grouped group-multiline>
          <div class="control">
            <b-button type="is-primary" outlined>Agregar</b-button>
          </div>
        </b-field>
        <b-table :data="schedules" :mobile-cards="true">
          <template slot-scope="props">
            <b-table-column label="Base de datos">
              <template v-if="props.row.customer_database">
              {{ props.row.customer_database.database }}
              </template>
            </b-table-column>
            <b-table-column label="Habilitado">
              <b-tag type="is-success" v-if="props.row.enabled">
                Si
              </b-tag>
              <b-tag type="is-danger" v-else>
                No
              </b-tag>
            </b-table-column>
            <b-table-column label="Dias">
              <weekdays :value="props.row.days"></weekdays>
            </b-table-column>
            <b-table-column label='Horario'>
              {{ props.row.time.hour | pad  }}:{{ props.row.time.minute | pad }}
            </b-table-column>
            <b-table-column>
              <div class="buttons is-primary">
                <button class="button" @click="onShceduleEdit(props.row)">
                  <b-icon icon="pencil"></b-icon>
                </button>
                <button class="button is-danger">
                  <b-icon icon="times"></b-icon>
                </button>
              </div>
            </b-table-column>
          </template>
        </b-table>
        <!--
        <b-modal :active.sync="isScheduleFormActive" has-modal-card>
            <ScheduleForm v-bind="formProps"></ScheduleForm>
        </b-modal>
        -->
        <b-loading :is-full-page="false" :active.sync="loadingSchedules" :can-cancel="false">
          <b-icon
              pack="fa"
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin">
          </b-icon>
          <h1>Cargando...</h1>
        </b-loading>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, mapState } from 'vuex';
import Weekdays from '../../Weekdays.vue';
import ScheduleForm from './Schedule/ScheduleForm.vue';
import ServerGrid from '../../ServerGrid.vue';
import InstanceGrid from '../../InstanceGrid.vue';
import CustomerdbsGrid from '../../CustomerdbsGrid.vue';

const self = this;
export default {
  components: {
    Weekdays,
    ServerGrid,
    InstanceGrid,
    CustomerdbsGrid,
  },
  data() {
    return {
      isScheduleFormActive: false,
      customersdbsColumns: [
        {
          field: '_id',
          label: 'ID',
        }, {
          field: 'database',
          label: 'Base de datos',
        },
      ],
      instanceColumns: [
        {
          field: '_id',
          label: 'ID',
        }, {
          field: 'name',
          label: 'Nombre',
        }, {
          field: 'sql_server',
          label: 'Conexion',
        }, {
          field: 'sql_auth',
          label: 'Autenticacion',
        }, {
          field: 'version',
          label: 'Version SQL',
        },
      ],
      schedulesColumns: [
        {
          field: '_id',
          label: 'ID',
        }, {
          field: 'name',
          label: 'Nombre',
        }, {
          field: 'server',
          label: 'Servidor',
        },
      ],
      columns: [
        {
          field: '_id',
          label: 'ID',
        }, {
          field: 'name',
          label: 'Nombre',
        },
      ],
    };
  },
  filters: {
    pad: (value) => {
      if (!value) return '00';
      // return moment(value).format('DD-MM-YYYY HH:mm:ss');
      return (`0${value}`).slice(-2);
    },
  },
  computed: {
    ...mapGetters('servers', ['servers', 'loadingServers']),
    ...mapGetters('customers', ['customers', 'loadingCustomers']),
    ...mapGetters('schedules', ['schedules', 'loadingSchedules']),
    ...mapGetters('instances', ['instances', 'loadingInstances']),
    ...mapGetters('customersdbs', ['customersdbs']),
  },
  methods: {
    ...mapActions('servers', ['fetchServers']),
    ...mapActions('customers', ['fetchCustomers']),
    ...mapActions('schedules', ['fetchSchedules']),
    ...mapActions('instances', ['fetchInstances', 'fetchInstancesByServer']),
    ...mapActions('customersdbs', ['fetchCustomersdbs', 'fetchCustomerdbsByInstance']),
    onServerDetail(row, index) {
      this.fetchInstancesByServer({ server: row._id });
    },
    onInstanceDetail(row, index) {
      this.fetchCustomerdbsByInstance({ instance: row._id });
    },
    tabChange(index) {
      console.log(index);
      if (index === 0) {
        this.fetchCustomers();
      }
      if (index == 1) {
        this.fetchServers();
      }
      if (index == 3) {
        this.fetchInstances();
      }
      if (index == 4) {
        this.fetchCustomerdbs();
      }
      if (index == 5) {
        this.fetchSchedules();
      }
    },
    onShceduleEdit(schedule) {
      console.log(schedule);
      // isScheduleFormActive = true;
      this.$modal.open({
        parent: this,
        component: ScheduleForm,
        hasModalCard: true,
        props: {
          value: schedule,
        },
      });
    },
  },
  mounted() {
    // this.fetchServers();
    this.fetchCustomers();
  },
};
</script>
