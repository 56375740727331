<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Agregar usuario</p>
    </header>
    <ValidationObserver slim v-slot="{ invalid, validate  }">
      <form @submit.prevent="validateBeforeSubmit">
        <section class="modal-card-body">
          <b-field label="Nombre" :type="errors.first('nombre') ? 'is-danger' : ''" :message="errors.first('nombre')"
            data-vv-validate-on="change|blur">
            <b-input name="nombre" v-validate="'required'" v-model="model.profile.name"
              data-vv-validate-on="change|blur"></b-input>
          </b-field>
          <b-field label="Email" :type="errors.first('email') ? 'is-danger' : ''" :message="errors.first('email')">
            <b-input type="email" name="email" v-validate="'required|email'" data-vv-validate-on="change|blur"
              v-model="model.email">
            </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button type="is-primary" @click="$parent.close()">Cerrar</b-button>
          <b-button type="is-primary"  native-type="submit" :disabled="!isFormValid" >Guardar</b-button>
        </footer>
      </form>
    </ValidationObserver>


  </div>
</template>

<script>
import { RopositoryFactory } from '@/repository/RepositoryFactory';
import EventBus from '@/event-bus';
import { ValidationObserver } from 'vee-validate';

const usersRepository = RopositoryFactory.get('users');

export default {
  data() {
    return {
      model: {
        profile: {
          name: '',
        },
        email: '',
      },
    };
  },
  mounted() {
  },
  computed: {
    isFormValid() {
      return this.errors.items.length === 0;
    },
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          usersRepository.add(this.$data.model)
            .then((saved) => {
              this.$toast.open({
                message: 'Usuario creado',
                type: 'is-success',
                position: 'is-bottom',
              });
              EventBus.$emit('user-updated', saved._id);
              this.$parent.close();
            })
            .catch((err) => {
              this.$toast.open({
                message: `Error al guardar\r\n${err}`,
                type: 'is-danger',
                position: 'is-bottom',
              });
            });
        } else {
          this.$toast.open({
            message: 'Datos no válidos',
            type: 'is-danger',
            position: 'is-bottom',
          });
        }
      });
    },
  },
  // props:['value'],
  filters: {
  },
  components: {
    ValidationObserver
  },
};
</script>
