/* eslint-disable no-param-reassign */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-shadow */
import axios from 'axios';
import { authHeader } from '@/helpers';
import config from '../../config';

import {
  SERVER_REQUEST,
  SERVER_SUCCESS,
  SERVER_ERROR,
} from '../mutation-types';


const state = {
  servers: [],
  loadingServers: false,
};

const getters = {
  servers: state => state.servers,
  loadingServers: state => state.loadingServers,
};

const actions = {
  fetchServers(store) {
    return new Promise((resolve, reject) => {
      store.commit(SERVER_REQUEST, { });
      axios.get(config.API.SERVER, {
        headers: authHeader(store),
      }).then((response) => {
        // if (response.data) {
        store.commit(SERVER_SUCCESS, { servers: response.data });
        resolve();
        // } else {
        //  reject(data.error);
        // }
      }).catch(() => {
        store.commit(SERVER_ERROR);
        reject();
        // reject('Error sending request to server!');
      });
    });
  },
};
const mutations = {
  [SERVER_SUCCESS](store, data) {
    store.servers = data.servers;
    store.loadingServers = false;
  },
  [SERVER_REQUEST](store) {
    store.loadingServers = true;
  },
  [SERVER_ERROR](store) {
    store.loadingServers = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
