import { authHeaderUser } from '@/helpers';
import Repository from './Repository';

const resource = '/admin/users';
export default {
  get() {
    return Repository.get(`${resource}`, { headers: authHeaderUser() });
  },
  add(user) {
    return Repository.post(`${resource}`, user, { headers: authHeaderUser() });
  },
  delete({ userId }) {
    return Repository.delete(`${resource}/${userId}`, { headers: authHeaderUser() });
  },
  addCustomer({ userId, customerId }) {
    return Repository.post(`${resource}/${userId}/customers`, { customer: customerId }, { headers: authHeaderUser() });
  },
  deleteCustomer({ userId, customerId }) {
    return Repository.delete(`${resource}/${userId}/customers/${customerId}`, { customer: customerId }, { headers: authHeaderUser() });
  },
};
