<template>
  <b-table :data="data" :mobile-cards="true" :columns="columns" detailed detail-key="_id" @details-open="onDetail">
    <template slot="detail" slot-scope="props">
      <instance-grid :key="props._id"  :data="instances">
      </instance-grid>
        <b-loading :is-full-page="false" :active.sync="loadingInstances" :can-cancel="false">
          <b-icon
              pack="fa"
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin">
          </b-icon>
          <h1>Cargando...</h1>
        </b-loading>
    </template>
  </b-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InstanceGrid from './InstanceGrid.vue';

export default {
  components: {
    InstanceGrid,
  },
  props: ['data'],
  data() {
    return {
      columns: [
        {
          field: '_id',
          label: 'ID',
        }, {
          field: 'name',
          label: 'Nombre',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('instance', ['instances', 'loadingInstances']),
  },
  methods: {
    ...mapActions('instance', ['fetchInstancesByServer']),
    onDetail(row, index) {
      this.fetchInstancesByServer({ server: row._id });
    },
  },
  mounted() {
    // this.fetchServers();
  },
};
</script>
