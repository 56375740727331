import store from '@/store';

// eslint-disable-next-line import/prefer-default-export
export function authHeader(_store) {
  // const bearerToken = store.rootGetters.token;
  const bearerToken = _store.rootGetters['user/token'];
  if (bearerToken) {
    return { Authorization: `Bearer ${bearerToken}` };
  }
  return {};
}

export function authHeaderUser() {
  // const bearerToken = store.rootGetters.token;
  const bearerToken = store.getters['user/token'];
  if (bearerToken) {
    return { Authorization: `Bearer ${bearerToken}` };
  }
  return {};
}
