import { authHeaderUser } from '@/helpers';
import Repository from './Repository';

const resource = '/admin/customers';
export default {
  get() {
    return Repository.get(`${resource}`, { headers: authHeaderUser() });
  },
  delete(customerId) {
    return Repository.delete(`${resource}/${customerId}`, { headers: authHeaderUser() });
  }
};
